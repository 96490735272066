import React from "react";

const WhatWeDoPage: React.FC = () => {
  return (
    <React.Fragment>
      <div className="paper" style={{ textAlign: "center" }}>
        <h2>Add-ons for major platforms extending standard features</h2>
      </div>
    </React.Fragment>
  );
};

export default WhatWeDoPage;
