import React from "react";
import Menu from "./Menu";
import LogoBox from "./Logobox";
import ClickOutside from "./ClickOutside";

type navBarProps = {
  onOpen: boolean;
  onToggle: () => void;
  onClickAway: () => void;
};

export const Navbar: React.FC<navBarProps> = ({ onOpen, onToggle, onClickAway }) => {
  return (
    <ClickOutside onToggle={onClickAway}>
      <div className="navbar">
        <div className="headercontainer">
          <LogoBox show={!onOpen} />
          <Menu onToggle={onToggle} onOpen={onOpen} />
        </div>
      </div>
    </ClickOutside>
  );
};
