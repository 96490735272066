import React, { useState } from "react";
import { Navbar } from "./components/Navbar";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import WhatWeDoPage from "./pages/WhatWeDoPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const App: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const pagesClassName = menuOpen ? "pages responsive" : "pages";

  const toggleHandler = () => {
    setMenuOpen((prev) => !prev);
  };

  const clickAwayToggleHandler = () => {
    setMenuOpen((prev) => prev && false);
  };

  return (
    <React.Fragment>
      <div className="backgroundcolor" />
      <div className="backgroundimage" />

      <BrowserRouter>
        <Navbar onToggle={toggleHandler} onClickAway={clickAwayToggleHandler} onOpen={menuOpen} />
        <div className={pagesClassName}>
          <Routes>
            <Route element={<HomePage/>}     path="/" />
            <Route element={<WhatWeDoPage/>} path="/whatwedo" />
            <Route element={<AboutPage/>}    path="/about" />
            <Route element={<ContactPage/>}  path="/contact" />
          </Routes>
        </div>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
