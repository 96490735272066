import React, { useEffect } from "react";
// import family_1 from "../img/Family_01.png";
import MainCanvas from "../components/MainCanvas";
// import family_2 from "../img/Family_02.png";
// import family_3 from "../img/Family_03.png";
// import family_4 from "../img/Family_04.png";
import { facade, valve, electric } from "../components/sourceModelImporter";

const HomePage: React.FC = () => {
  function scrollBottom() {
    document.body.scrollTop = 2000; // For Safari
    document.documentElement.scrollTop = 2000; // For Chrome, Firefox, IE and Opera
  }

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  return (
    <React.Fragment>
      <div className="paper">
        <h1 className="marketgreetings">customized software solutions for BIM</h1>
        <h2>Bimgen creates tools and content to improve your digital engineering processes.</h2>
        <a href="https://rwm.bimgen.org">Redirect to rfa-parameters-extractor</a>
        <div>
          <button onClick={scrollBottom.bind(null)}>GOAHEAD</button>
        </div>
      </div>
      <div className="homepagebottom">
        <h1>Parametric models for engineering and construction trends</h1>
        <div className="vidgetsContainer">
          <div>
            <h2>Structure</h2>
            <MainCanvas example={facade} cameraPosition={{ x: -0.7, y: 0.5, z: 0.8 }} />
          </div>
          <div>
            <h2>MEP</h2>
            <MainCanvas example={valve} cameraPosition={{ x: -0.8, y: -0.2, z: 0.3 }} />
          </div>
          <div>
            <h2>Electric</h2>
            <MainCanvas example={electric} cameraPosition={{ x: -0.0, y: 0.2, z: 0.3 }} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomePage;
