import React from "react";
import Logo from "../img/BG_Symbol.png";

interface logoboxProps {
  show: boolean;
}

const Logobox: React.FC<logoboxProps> = ({ show }) => {
  const className = show ? "logobox" : "logobox responsive";

  return (
    <a href="https://www.bimgen.org/">
      <div className={className} id="logobox">
        <div className="logotext">
          <h2>BIM</h2>
        </div>
        <img src={Logo} alt="logoimg" height="45px" />
        <div className="logotext">
          <h2>GEN</h2>
        </div>
      </div>
    </a>
  );
};

export default Logobox;
