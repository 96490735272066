import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

function MainCanvas({ example, cameraPosition }) {
  const mount = useRef(null);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);

    var scene = new THREE.Scene();
    scene.background = new THREE.Color(0xF2F2F2);

    var loader = new GLTFLoader();
    loader.load(
      example,
      (gltf) => {
        gltf.scene.receiveShadow = true
        // var wireframeMaterial = new THREE.MeshBasicMaterial( { color: 0x000000, wireframe: true, transparent: true } );
        // console.log(gltf)
        // var wireframeMaterial = new THREE.MeshBasicMaterial( { color: 0x000000, wireframe: true, transparent: true } );
        // var mesh = new THREE.Mesh( gltf.scene, wireframeMaterial );
        // scene.add(mesh);
        scene.add(gltf.scene);
      },
      undefined,
      (error) => {
        console.log(error);
      }
    );

    {
      const color = 0xffffff;
      const intensity = 0.6;
      const ambientLightProbe = new THREE.AmbientLightProbe(0xffffff, 0.4);
      const spotLight1 = new THREE.SpotLight(color, intensity);
      const spotLight2 = new THREE.SpotLight(color, intensity);
      spotLight1.position.set(-5, 5, 10);
      spotLight2.position.set(5, 5, 10);
      scene.add(ambientLightProbe);
      scene.add(spotLight1);
      scene.add(spotLight2);
    }

    var camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.x = cameraPosition.x;
    camera.position.y = cameraPosition.y;
    camera.position.z = cameraPosition.z;

    var renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    mount.current.appendChild(renderer.domElement);

    var controls = new OrbitControls(camera, renderer.domElement);
    controls.minDistance = 0.2;
    controls.maxDistance = 1.2;
    controls.enableZoom = true;
    
    function animate() {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    }
    animate();

    updateDimensions();
    function updateDimensions() {
      if (mount.current !== null) {
        renderer.setSize(mount.current.clientWidth, mount.current.clientHeight);
        camera.aspect = mount.current.clientWidth / mount.current.clientHeight;
        camera.updateProjectionMatrix();
        renderer.render(scene, camera);
        console.log("Demension is updated");
      }
    }
    console.log("Canvas mount");
    const mountCurrent = mount.current;
    return () => {
      if (mountCurrent !== null) {
        const canvas = mountCurrent.getElementsByTagName("canvas");

        mountCurrent.removeChild(canvas[0]);
        console.log("Removing canvas");
      }
      window.removeEventListener("resize", updateDimensions);
      console.log("Remove eventListner");
    };
  }, [cameraPosition.x, cameraPosition.y, cameraPosition.z, example, mount]);

  return (
    <div className="mountwrapper">
      <div className="mount" ref={mount} />
    </div>
  );
}

export default MainCanvas;
