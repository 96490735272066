import React from "react";

interface Props {}

const AboutPage: React.FC<Props> = () => {
  return (
    <div className="paper">
      <h1>Tech stack:</h1>
      <h3>- Autodesk Forge</h3>
      <h3>- Autodesk FBX SDK</h3>
      <h3>- Autodesk Revit</h3>
      <h3>- Revit API</h3>
      <h3>- Amazon AWS</h3>
      <h3>C# | Python | TypeScript | Node.js | React.js</h3>
      <hr />
      <h2>We are open for cooperation</h2>
    </div>
  );
};

export default AboutPage;
