import React, {useRef, useEffect} from "react";

const useClickOutside = (ref: any, onClickAway: () => void) => {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickAway();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClickAway, ref]);
};

function ClickOutside(props: any) {
    const wrapperRef = useRef(null);
    useClickOutside(wrapperRef, props.onToggle);

    return <div ref={wrapperRef}>{props.children}</div>;
}

export default ClickOutside;