import React from "react";
import { NavLink } from "react-router-dom";

interface menuProps {
  onOpen: boolean;
  onToggle: () => void;
}

const Menu: React.FC<menuProps> = ({ onOpen, onToggle }) => {
  const className = onOpen ? "menu responsive" : "menu";

  return (
    <div className={className} id="menu">
      <NavLink to="/">Home</NavLink>
      <NavLink to="/whatwedo">What we do</NavLink>
      <NavLink to="/about">About</NavLink>
      <NavLink to="/contact">Contact</NavLink>
      <button id="menubutton" onClick={onToggle.bind(null)}>
        <i className="fa fa-bars"></i>
      </button>
    </div>
  );
};

export default Menu;
