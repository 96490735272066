import React from "react";

interface Props {}

const ContactPage: React.FC<Props> = () => {
  return (
    <div className="paper">
      <h1>Contacts</h1>
      <div className="contacts">
        <div className="contactrow">
          <div className="contactcol">
            <i className="material-icons">local_phone</i>
          </div>
          <div className="contactcol">+79137344011</div>
        </div>
        <div className="contactrow">
          <div className="contactcol">
            <i className="material-icons">email</i>
          </div>
          <div className="contactcol">info@bimgen.app</div>
        </div>
        <div className="contactrow">
          <div className="contactcol">
            <i className="material-icons">location_on</i>
          </div>
          <div className="contactcol">Novosibirsk, Russian Fed.</div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
